$black: #000;
$white: #fff;

$brand-blue: #7191b2;
$brand-grey: #3e3e3e;

$xdark-faded-black: rgba(37, 37, 37, 0.75);
$dark-faded-black: rgba(37, 37, 37, 0.6);
$faded-black: rgba(37, 37, 37, 0.5);
$mid-faded-black: rgba(37, 37, 37, 0.2);
$faded-white: rgba(191, 191, 191, 0.5);
$faded-grey: rgba(205, 205, 205, 0.2);

:root {
    --header-light: #fff;
    --header-default: $brand-grey;
}

$llavender-grey: hsl(258, 11%, 90%);
$red: hsl(0, 100%, 50%);

$headerHeight: 100px;

// set px vars for consistent mobile responsiveness
