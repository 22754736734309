@import "./src/scss/global";
.question {
    width: 100%;
    margin-bottom: 20px;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 2px $mid-faded-black;
        &:hover {
            cursor: pointer;
        }
        > h3 {
            margin: 5px 0;
        }
    }
    p {
        text-align: left;
    }
    @media (max-width: 450px) {
        .title > h3 {
            font-size: 16px;
        }
        p {
            font-size: 14px;
        }
    }
}
