@import "./src/scss/global";
.textarea-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 40px;
    width: 100%;
    .required-container {
        position: relative;
        left: -25px;
        width: 0px;
        i {
            margin-top: 15px;
            font-size: 8px;
            color: $red;
        }
    }
    textarea {
        display: flex;
        flex-direction: column;
        flex: 10;
        width: 100%;
        border-radius: 5px;
        border: none;
        background-color: $llavender-grey;
        padding-left: 25px;
        outline: none;
        transition: border 300ms ease-in-out;
        border: solid 2px transparent;
        resize: none;
        height: 150px !important;
        padding-top: 10px;
        font-size: 15px;
        &.invalid {
            border: solid 2px $red;
        }
    }
    label {
        width: auto;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        position: absolute;
        z-index: 900;
        color: $black;
        order: -1;
        margin-top: -10px;
        margin-left: 15px;
        padding-left: 15px;
        transition: all 0.3s ease-in;
        transform: translateY(20px);
        pointer-events: none;
        font-size: 15px;
        span {
            background: none;
            padding: 0 5px;
        }
    }
    textarea:focus + label,
    .input-with-value {
        transform: translate(-20px, -15px);
        transform: font-size 0.3s;
        font-size: 16px;
        color: $white;
    }
}
