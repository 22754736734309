@import "./src/scss/global";
.previous-work {
    @extend %flex-center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
    .projects {
        margin-top: 50px;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
        justify-content: center;
    }
}
