@import "./src/scss/global";
.partners {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    h1 {
        text-transform: uppercase;
    }
}
