@import "./src/scss/global";
.menu-item {
    --header-colour: var(--header-default);
    &.light {
        --header-colour: var(--header-light);
    }
    width: 150px;
    height: 50px;
    text-align: center;
    font-size: 17px;
    transition: all 0.6s ease;
    font-weight: 400;
    line-height: 45px;
    text-decoration: none;
    letter-spacing: 0;
    flex-shrink: 0;
    user-select: none;
    color: var(--header-colour);
    &::after,
    &::before {
        content: ' ';
        color: var(--header-colour);
        backface-visibility: hidden;
        border: 1px solid transparent;
        bottom: 0px;
        display: block;
        margin: 0 auto;
        position: relative;
        transition: all 280ms ease-in-out;
        width: 0;
    }
    &.active {
        cursor: default;
        letter-spacing: 3px;
        transition-duration: 0.6s;
        &::after {
            border: 1px solid var(--header-colour);
            backface-visibility: hidden;
            transition: all 350ms ease-in-out;
            width: 70%;
        }
    }
    &:not(.active) {
        &:hover {
            cursor: pointer;
            letter-spacing: 3px;
            &::before {
                bottom: auto;
                top: 0;
                width: 70%;
            }
            &::after,
            &::before {
                backface-visibility: hidden;
                border-color: var(--header-colour);
                transition: width 350ms ease-in-out;
                width: 70%;
            }
        }
    }
    &:hover {
        color: var(--header-colour);
    }
}
