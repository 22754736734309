@import "./src/scss/global";
.youtube {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
