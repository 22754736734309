@import "./src/scss/global";
.image-project {
    height: 196px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
        max-width: 350px;
        max-height: 196px;
    }
    i {
        font-weight: 900;
        &:hover {
            cursor: pointer;
        }
    }
}
