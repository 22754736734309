* {
    box-sizing: border-box;
    font-family: 'Nunito Sans';
}
html {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
button {
    background-color: transparent;
    border: none;
}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 600px) {
    h1 {
        font-size: 5vw;
    }
}
