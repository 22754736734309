@import "./src/scss/global";
.button {
    color: $white;
    height: 50px;
    text-align: center;
    width: 250px;
    transition: all 0.5s;
    position: relative;
    line-height: 50px;
    font-size: 18px;
    &:hover {
        cursor: pointer;
        &::before {
            opacity: 0;
            transform: scale(0.5, 0.5);
        }
        &::after {
            opacity: 1;
            transform: scale(1, 1);
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        color: rgb(205, 205, 205);
        border: 2px solid $white;

        transition: all 0.3s;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
        background-color: $faded-white;
        transform: scale(1.2, 1.2);
    }
}
