@import "./src/scss/global";
.contact-us {
    background-image: radial-gradient($faded-black, transparent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    .contact-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        h1 {
            text-transform: uppercase;
            margin: 15px;
            font-size: 40px;
        }
        h3 {
            margin: 5px;
            font-size: 18px;
            font-weight: normal;
        }
    }
    .socials {
        margin-top: 50px;
    }
    .faqs {
        margin: 50px 0 30px 0;
    }
}
