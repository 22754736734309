@import "./src/scss/global";
.category-selector {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 10px;
    @media (max-width: 850px) {
        justify-content: center;
        column-gap: 30px;
    }
}
