@import "./src/scss/global";
.profile {
    position: relative;
    background-size: cover;
    background-position: center;
    .overlay {
        background-color: $mid-faded-black;
        @extend %overlay;
        position: relative;
        justify-content: flex-end;
        > * {
            pointer-events: auto;
        }
        transition: background-color 0.5s ease-in-out;
        &.info-visible {
            background-color: $dark-faded-black;
        }
        .button {
            @extend %absolute-center;
            bottom: 15%;
        }
        .info {
            @extend %absolute-center;
            top: 20%;
            color: white;
            width: 60%;
            font-size: 20px;
            i {
                position: absolute;
                top: -45px;
                right: -35px;
                font-size: 23px;
                padding: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        @media (min-width: 851px) {
            pointer-events: auto;
            .info > i {
                display: none;
            }
            .button {
                display: none;
            }
        }
        @media (max-width: 450px) {
            .info {
                h2 {
                    font-size: 6vmin;
                }
                h3 {
                    font-size: 5vmin;
                }
                p {
                    font-size: 4vmin;
                }
            }
        }
    }
}
