@import "./src/scss/global";
.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inputs {
        width: 80vw;
        max-width: 700px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 35px;
        column-gap: 25px;
        margin-bottom: 50px;
        .text-input {
            width: calc(50% - 12.5px);
            @media (max-width: 700px) {
                width: 100%;
            }
        }
    }
}
