@import "./src/scss/global";
.carousel-item {
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    > img {
        height: 100%;
        width: auto;
        max-width: 200px;
        object-fit: contain;
        padding: 0 20px;
        @media (max-width: 550px) {
            padding: 0 10px;
            max-width: 100px;
        }
    }
}
