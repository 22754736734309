@import "./src/scss/global";
.show-reel {
    position: relative;
    width: 100%;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    .overlay {
        background-color: $faded-black;
        @extend %overlay;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            text-align: center;
            color: $white;
            font-weight: bold;
            font-size: 62px;
            margin: 20px;
            @media (max-width: 800px) {
                font-size: 6vw;
            }
        }
    }
}
