%overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

%absolute-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
