@import "./src/scss/global";
.main-layout {
    main {
        width: 100vw;
        min-height: calc(100vh - $headerHeight);
        &.overlay-header {
            min-height: 100vh;
        }
    }
}
