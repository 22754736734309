@import "./src/scss/global";
header {
    background-color: $brand-grey;
    --header-colour: var(--header-default);
    &.light {
        --header-colour: var(--header-light);
    }
    width: 100%;
    height: $headerHeight;
    display: flex;
    justify-content: center;
    &.overlay {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
    }
    img {
        position: absolute;
        max-height: $headerHeight;
        max-width: 35vw;
        left: 3%;
        flex-shrink: 0;
        &.logo-small {
            display: none;
        }
    }
    i {
        position: absolute;
        z-index: 20;
        top: 35px;
        right: 10%;
        font-size: 28px;
        display: none !important;
        color: var(--header-colour);
        &:hover {
            cursor: pointer;
        }
    }
    nav {
        display: flex;
        width: 60%;
        justify-content: space-around;
        align-items: center;
        margin-left: auto;
    }
    @media (max-width: 1000px) {
        img {
            &.logo-default {
                display: none;
            }
            &.logo-small {
                display: block;
            }
        }
        nav {
            padding-right: 60px;
            .menu-item {
                font-size: 16px;
            }
        }
    }
    @media (max-width: 850px) {
        i {
            display: block !important;
        }
        nav {
            z-index: 15;
            width: 100vw;
            height: 100vh;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0;
            padding: 20% 0 0 0;
            background-color: $xdark-faded-black;
            .menu-item {
                font-size: 20px;
                margin-bottom: 55px;
            }
            &:not(.menu-visible) {
                transform: translateX(-1000px);
            }
        }
    }
}
