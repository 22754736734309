@import "./src/scss/global";
.project {
    width: 350px;
    height: 375px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
}
