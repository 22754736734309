@import "./src/scss/global";
.about-us {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        .profile {
            height: 100vh;
            &:first-child {
                height: calc(100vh - $headerHeight);
            }
        }
    }
}
