@import "./src/scss/global";
.carousel {
    width: 90%;
    display: flex;
    align-items: center;
    max-height: 150px;
    .icon-container {
        @extend %flex-center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        &:hover {
            cursor: pointer;
            background-color: $brand-blue;
        }
        i {
            @media (max-width: 550px) {
                font-size: 12px;
            }
            &.fa-chevron-left {
                transform: translateX(-1px);
            }
            &.fa-chevron-right {
                transform: translateX(1px);
            }
        }
    }
    .item-list {
        width: calc(100% - 60px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow: hidden;
        height: 150px;
        @media (max-width: 1060px) {
            height: 125px;
        }
        @media (max-width: 550px) {
            height: 75px;
        }
    }
}
