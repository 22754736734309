@import "./src/scss/global";
.company-info {
    background-color: $brand-grey;
    color: $white;
    padding: 175px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        width: 60%;
        margin: 12px 0;
        @media (max-width: 1200px) {
            width: 80%;
        }
    }
}
